import authRoutes from "./authRoutes";

import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import mainRoutes from "./mainRoutes";
import { NotFoundPage } from "../pages";
import PrivateRoute from "./PrivateRoute";

const routes = [...authRoutes, ...mainRoutes];
const AppRouter = () => {
  const isAuthenticated = useSelector((state: any) => state.user?.user);
  console.log(isAuthenticated);
  return (
    <Router>
      <Suspense fallback={<div>Loading..</div>}>
        <Routes>
          {routes.map((route, index) => {
            if (route.protected) {
              // Render protected route
              return (
                <Route key={index} element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
                  <Route path={route.path} element={route.element} />
                </Route>
              );
            } else if (route.path === "/login" && isAuthenticated) {
              // Prevent authenticated users from accessing the login page
              return <Route key={index} path={route.path} element={<Navigate to="/" replace />} />;
            } else {
              // Render public route
              return <Route key={index} path={route.path} element={route.element} />;
            }
          })}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
