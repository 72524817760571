import { Button, DatePicker, Flex, Modal, Radio, Segmented, Space } from "antd";
import React, { useState } from "react";
import SearchInput from "./SearchInput";
import SelectSearchFilter from "./SelectSearchFilter";
import AgeSlider from "./AgeSlider";

const { RangePicker } = DatePicker;

const UserFilterModal = ({ open, setOpen }) => {
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [ageRange, setAgeRange] = useState([20, 50]);
  const [gender, setGender] = useState("male");

  const handleSliderChange = value => {
    setAgeRange(value);
  };

  const handleReset = () => {
    setSearch('')
    setFilter(null)
    setDateRange(null)
    setAgeRange([20, 50])
    setGender('male')
  }

  return (
    <Modal
      centered
      title="Fiilter User"
      footer={null}
      open={open}
      style={
        {
          // minWidth: "80vw",
        }
      }
      styles={{
        body: {
          maxHeight: "80vh",
          overflowY: "auto",
          scrollbarWidth: "none",
          padding: 20,
        },
      }}
      onCancel={() => {
        setOpen(false);
      }}>
      <Flex vertical gap={16}>
        <SearchInput search={search} setSearch={setSearch} />
        <RangePicker
        value={dateRange}
          onChange={(values: any) => {
            if (values) {
              setDateRange(values)
            } else {
              setDateRange(null)
            }
          }}
        />
        <SelectSearchFilter filter={filter} setFilter={setFilter} />
        <AgeSlider ageRange={ageRange} onSliderChange={handleSliderChange} />
        <Radio.Group value={gender} onChange={e => setGender(e.target.value)}>
          <Radio value={"male"}>Male</Radio>
          <Radio value={"female"}>Female</Radio>
        </Radio.Group>
        <Space style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button type="primary">Filter</Button>
          <Button type='default' onClick={handleReset}>Reset</Button>
        </Space>
      </Flex>
    </Modal>
  );
};

export default UserFilterModal;
