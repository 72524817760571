// Redux actions and reducer

import { createSlice } from "@reduxjs/toolkit";

interface ServiceState {
  parentId: string;
  title: string;
}

const initialState = {
  path: [] as ServiceState[],
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServiceState(state, { payload }) {
      // Add the payload to path only if it's not already present
      const existingIndex = state.path.findIndex(path => path.parentId === payload.parentId);
      if (existingIndex === -1) {
        state.path.push(payload);
      }
    },
    setPathToIndex(state, { payload: index }) {
      state.path = state.path.slice(0, index + 1);
    },
    resetPath(state) {
      state.path = [];
    },
    cleanPathAfterDelete(state, { payload: serviceId }) {
      // Find the index of the deleted item in the path
      const deleteIndex = state.path.findIndex(path => path.parentId === serviceId);

      // If the item exists in the path, remove it and all items after it
      if (deleteIndex !== -1) {
        state.path = state.path.slice(0, deleteIndex);
      }
    },
  },
});

export const { setServiceState, setPathToIndex, resetPath, cleanPathAfterDelete } =
  serviceSlice.actions;
export default serviceSlice.reducer;
