import { useMobileDimensions } from "@/hooks";
import { Flex, Slider } from "antd";
import React, { useState } from "react";

const AgeSlider = ({ageRange, onSliderChange}) => {
  const {isMobile} = useMobileDimensions()
  return (
    <Flex style={{ width: "100%", gap: isMobile ? 4 : 8 , alignItems: 'center' }}>
      <span>Age : </span>
      <span>{ageRange[0]}</span>
      <Slider
        style={{ width: isMobile ? "65%" : "75%" }}
        range
        defaultValue={ageRange}
        onChange={onSliderChange}
      />
      <span>{ageRange[1]}</span>
    </Flex>
  );
};

export default AgeSlider;
