// Redux actions and reducer

import { createSlice } from "@reduxjs/toolkit";

interface SocketState {
  sockets: {};
}

const initialState: SocketState = {
  sockets: {},
};

const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    setSocket(state, action) {
      const { serverKey, socketId } = action.payload;
      state.sockets[serverKey] = socketId;
    },
  },
});

export const { setSocket } = socketSlice.actions;
export default socketSlice.reducer;
