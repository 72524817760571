export const S3_BUCKET = "ulsancham";
export const REGION = "ap-northeast-2";
export const ACCESS_KEY = "AKIAUSJEULGCJZX72BYC";
export const SECRET_ACCESS_KEY = "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";
import { S3Client, PutObjectCommand, DeleteObjectCommand } from "@aws-sdk/client-s3";

const s3 = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  },
});

export const awsUploadImage = async (image, folderName, userId, type) => {
  const fileName = `${userId}_${new Date().getTime()}.${type}`;
  const fileKey = `${folderName}/${fileName}`; // Combine folder name and file name
  try {
    const command = new PutObjectCommand({
      Bucket: S3_BUCKET, // Replace with your bucket name
      Key: fileKey, // Full path in the bucket
      Body: image, // File data
      ContentType: type === "video" ? "video/mp4" : "image/jpeg", // Content type
    });
    await s3.send(command);

    // Construct the public URL
    const fileUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${fileKey}`;
    console.log("File uploaded successfully:", fileUrl);
    return fileUrl;
  } catch (exception) {
    console.log(exception);
    /* handle the exception */
  }
};

export const awsDeleteImage = async s3Url => {
  try {
    // Extract the file key from the S3 URL
    const bucketDomain = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/`;
    const fileKey = s3Url.replace(bucketDomain, ""); // Example: ulsancham/admin_1731993004728.image

    // Check if fileKey is valid
    if (!fileKey) {
      throw new Error("Invalid S3 URL: Unable to extract file key.");
    }

    // Create the DeleteObjectCommand
    const command = new DeleteObjectCommand({
      Bucket: S3_BUCKET, // Your bucket name
      Key: fileKey, // File key (path) in the bucket
    });

    // Send the delete command to S3
    const response = await s3.send(command);
    console.log("File deleted successfully:", response);
    return response;
  } catch (exception) {
    console.error("Error deleting file:", exception);
    throw exception; // Rethrow to handle errors appropriately
  }
};
