import React, { useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space } from "antd";
import type { InputRef } from "antd";
let index = 0;

const SelectSearchFilter = ({ filter, setFilter }) => {
  const [items, setItems] = useState(["name", "email", "phone"]);
  const inputRef = useRef<InputRef>(null);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setItems([...items, filter || `New item ${index++}`]);
    setFilter("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Select
      value={filter}
      style={{ width: "100%" }}
      placeholder="Select filter"
      onChange={e => {
        setFilter(e);
      }}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: "8px 0" }} />
          <Space style={{ padding: "0 8px 4px" }}>
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={filter}
              onChange={onNameChange}
              onKeyDown={e => e.stopPropagation()}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add item
            </Button>
          </Space>
        </>
      )}
      options={items.map(item => ({ label: item, value: item }))}
    />
  );
};

export default SelectSearchFilter;
