import styled from "styled-components";

export const StyledUserActions = styled.article`
  & > * + * {
    margin-top: 1rem;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledBody = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  gap: 1rem;
`;
