import React from "react";
import { Form, Select } from "antd";

const ServiceParentSelect = ({ setParent, otherServices }) => {
  const handleSelectType = value => {
    const selectedItem = otherServices.find(item => item._id === value);
    setParent({
      _id: value,
      title: selectedItem?.title || "",
    });
  };

  const options =
    otherServices?.map?.(item => ({
      value: item._id,
      label: item.title || "Unnamed Service",
    })) || [];

  return (
    <Form.Item
      label="Select Service Parent"
      name="parentId"
      labelCol={{ span: 24 }}
      style={{ width: "100%" }}
      required>
      <Select
        showSearch
        onChange={handleSelectType}
        placeholder="Select a Service Parent"
        optionFilterProp="label"
        options={[{ value: "", label: "No Parent" }, ...options]}
      />
    </Form.Item>
  );
};

export default ServiceParentSelect;
