import React from "react";
import App from "./App";
import { persistor, store } from "./stores";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

const queryClient = new QueryClient();
const helmetContext = {};

const AppProvider = () => {
  return (
    <HelmetProvider context={helmetContext}>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </ReduxProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default AppProvider;
