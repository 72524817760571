import React from "react";
import { dotPulse } from "ldrs";
import InfiniteScroll from "react-infinite-scroll-component";

dotPulse.register();

const CFlatList = ({
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,

  // threshold = number of items before the end of the list to fetch more items
  onEndReachedThreshold = 0.8,

  // Callback function responsible for loading the next page of items.
  loadNextPage,

  layout = "vertical",
  data,
  dataLength,
  renderItems,
  style,
  loading = false,
}: {
  hasNextPage: boolean;
  loadNextPage: () => Promise<any>;
  onEndReachedThreshold?: number;
  resetDependencies?: any;
  listHeaderComponent?: React.Component;
  dataLength: number;
  renderItems: (item: any, index: any) => React.ReactNode;
  layout?: "horizontal" | "vertical";
  style?: React.CSSProperties;
  loading?: boolean;
  data?: any[];
}) => {
  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={loadNextPage}
      hasMore={hasNextPage}
      scrollableTarget="scrollableDiv"
      loader={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
            height: "20px",
          }}>
          {loading ? <l-dot-pulse size="43" speed="1.1" color="#6335b4"></l-dot-pulse> : null}
        </div>
      }
      style={{
        width: "100%",
        paddingBottom: "80px",
        ...style,
      }}
      scrollThreshold={onEndReachedThreshold}>
      {data?.map((item, index) => {
        return <div key={index.toString()}>{renderItems(item, index)}</div>;
      })}
    </InfiniteScroll>
  );
};

export default CFlatList;
