import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  category: "",
  title: "",
  instruction: "yes",
  coverImage: "",
  userId: "",
  contentSections: [
    {
      id: nanoid(),
      caption: "",
      content: [] as any[],
    },
  ],
};

export const blog = createSlice({
  name: "blog",
  initialState: initialState,
  reducers: {
    clearBlogState(state) {
      return initialState;
    },
    setBlogState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    editContent(state, { payload }) {
      const { contentId, sectionId, text, type, description } = payload;
      const sectionIndex = state.contentSections.findIndex(section => section.id === sectionId);
      if (sectionIndex >= 0) {
        const section = state.contentSections[sectionIndex];
        const contentIndex = section.content.findIndex(content => content.id === contentId);
        const contentItem = section.content[contentIndex];
        if (
          contentItem &&
          (type === "IMAGE" ||
            type === "VIDEO" ||
            type === "LINK" ||
            type === "QUOTE" ||
            type === "TEXT")
        ) {
          contentItem.text = text;
          contentItem.description = description;
          contentItem.type = type;
        }
      }
    },

    // Blog Sections Reducers
    addSection(state) {
      const newSection = {
        id: nanoid(),
        caption: "",
        content: [
          {
            id: nanoid(),
            text: null,
            description: "",
            type: "TEXT",
          },
        ],
      };
      state.contentSections.push(newSection);
    },
    removeSection(state, { payload }) {
      state.contentSections = state.contentSections.filter(section => section.id !== payload);
    },
    addCaption(state, { payload }) {
      const { id, value } = payload;
      const section = state.contentSections.find(section => section.id === id);

      if (section) {
        section.caption = value;
      }
    },
    addContent(state, { payload }) {
      const { sectionId, type, text, description } = payload;
      const newContent = {
        id: nanoid(),
        type: type,
        text: text,
        description: description ?? "",
      };
      const section = state.contentSections.find(section => section.id === sectionId);
      if (section) {
        section.content.push(newContent);
      }
    },
    editDescription(state, { payload }) {
      const { sectionId, contentId, description } = payload;
      const section = state.contentSections.find(section => section.id === sectionId);
      if (section) {
        const content = section.content.find(content => content.id === contentId);
        if (content) {
          content.description = description;
        }
      }
    },
    deleteContent(state, { payload }) {
      const { sectionId, contentId } = payload;
      // Find the section by sectionId
      const sectionIndex = state.contentSections.findIndex(section => section.id === sectionId);

      if (sectionIndex >= 0) {
        // Filter out the content by contentId
        state.contentSections[sectionIndex].content = state.contentSections[
          sectionIndex
        ].content.filter(content => content.id !== contentId);
      }
    },
  },
});
export const {
  addSection,
  removeSection,
  addCaption,
  addContent,
  deleteContent,
  setBlogState,
  editContent,
  clearBlogState,
  editDescription,
} = blog.actions;

export default blog.reducer;
