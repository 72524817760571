import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const initialState: any = {
  token: null,
  user: null,
  location: null,
  activeKey: "Dashboard",
};
export const usersSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    loginData(state, { payload }) {
      const user = payload;

      const decoded: any = user?.access_token ? jwtDecode(user?.access_token) : null;

      if (decoded) {
        console.log(state.user);
        state.user = user?.user;
        state.token = user?.access_token;
      } else {
        console.log(decoded);
        state.user = null;
      }
    },
    setItem: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    logout(state) {
      state.user = null;
      state.token = null;
      state.location = null;
      state.activeKey = "Dashboard";
    },
    empty(state) {
      state.user = null;
      state.token = null;
      state.location = null;
    },
  },
});
export const { loginData, logout, empty, setItem } = usersSlice.actions;
export default usersSlice.reducer;
