import React from "react";
import { Form, Input } from "antd";

const ServiceTitle = ({ error }) => {
  return (
    <Form.Item
      label={"Service Title"}
      name={"title"}
      labelCol={{ span: 24 }}
      style={{ width: "100%" }}
      rules={[{ required: true, message: error }]}
      required>
      <Input placeholder={"Enter title"} name={"title"} size={"large"} />
    </Form.Item>
  );
};

export default ServiceTitle;
