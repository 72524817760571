import { Avatar, AvatarProps } from "antd";
import React from "react";
import CustomImage from "../CustomImage";

interface Props extends AvatarProps {}

const CustomAvatar = ({ size, src, icon, ...props }: Props) => {
  return (
    <Avatar
      size={size}
      {...props}
      src={src && <CustomImage src={src} width={size} />}
      icon={icon}
    />
  );
};

export default CustomAvatar;
