import { Form, Input } from 'antd'
import React from 'react'

const {TextArea} = Input

const BusinessTypeDescription = () => {
  return (
    <Form.Item
      label="Description"
      name="description"
      labelCol={{ span: 24 }}
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Please enter description." }]}
      required>
      <TextArea rows={4} placeholder="Type your business type description here" name="description"/>
    </Form.Item>
  )
}

export default BusinessTypeDescription
