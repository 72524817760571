import { CloseCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  gap: 16px;
  padding-bottom: 10px; // Optional: Adds space below the content for a better scrolling experience
  scrollbar-width: thin; // Optional: Styles for the scrollbar in Firefox
  &::-webkit-scrollbar {
    height: 6px; // Optional: Styles for the scrollbar in WebKit browsers
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc; // Optional: Color of the scrollbar thumb
    border-radius: 3px; // Optional: Round corners for the thumb
  }
`;

export const RemoveImageIcon = styled(CloseCircleFilled)`
    font-size: 20px;
    position: absolute;
    right: 10px;
    color: #f82424;
    top: 10px;
    cursor: pointer;
`
