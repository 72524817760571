import styled from "styled-components";

export const StyledChatroomsWrapper = styled.div`
  width: 100%;
  padding-inline: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
`;

export const StyledAvatarWrapper = styled.div`
  position: relative;
`;

export const StyledStatusCircle = styled.span<{ $status: string | undefined }>`
  width: 12px;
  height: 12px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  background-color: ${props => (props.$status === "active" ? "green" : "gray")};
  border-radius: 50%;
  border: 2px solid white;
`;

export const StyledName = styled.p`
  font-size: 0.8rem;
  font-weight: 700;
`;

export const StyledLastMessage = styled.span`
  font-size: 0.7rem;
  color: gray;
`;

export const StyledTime = styled.p`
  font-size: 0.8rem;
  font-weight: 600;
  color: gray;
`;