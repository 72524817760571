import { List } from "antd";
import React from "react";
import dummyProductLike from "./dummyProductLike";
import { CustomAvatar } from "@/App/components";

const ProductLikeList = () => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={dummyProductLike}
      renderItem={(item, index) => (
        <List.Item key={index}>
          <List.Item.Meta
            avatar={<CustomAvatar size={40} src={item?.likedUser?.profileImage} />}
            title={<a>{item?.likedUser?.username}</a>}
          />
        </List.Item>
      )}
    />
  );
};

export default ProductLikeList;
