export default [
    {
        _id: "1",
        likedUser: {
            _id: "1",
            username: "Kaung Htet Kyaw",
            profileImage: ""
        }
    },
    {
        _id: "2",
        likedUser: {
            _id: "2",
            username: "Soe Thet Paing Htwe",
            profileImage: ""
        }
    },
    {
        _id: "3",
        likedUser: {
            _id: "3",
            username: "Kaung Htet Kyaw",
            profileImage: ""
        }
    },
    {
        _id: "4",
        likedUser: {
            _id: "4",
            username: "Soe Thet Paing Htwe",
            profileImage: ""
        }
    },
    {
        _id: "5",
        likedUser: {
            _id: "5",
            username: "Kaung Htet Kyaw",
            profileImage: ""
        }
    },
    {
        _id: "6",
        likedUser: {
            _id: "6",
            username: "Soe Thet Paing Htwe",
            profileImage: ""
        }
    },
    {
        _id: "7",
        likedUser: {
            _id: "7",
            username: "Kaung Htet Kyaw",
            profileImage: ""
        }
    },
    {
        _id: "8",
        likedUser: {
            _id: "8",
            username: "Soe Thet Paing Htwe",
            profileImage: ""
        }
    },
    {
        _id: "9",
        likedUser: {
            _id: "9",
            username: "Kaung Htet Kyaw",
            profileImage: ""
        }
    },
    {
        _id: "10",
        likedUser: {
            _id: "10",
            username: "Soe Thet Paing Htwe",
            profileImage: ""
        }
    },
    {
        _id: "11",
        likedUser: {
            _id: "11",
            username: "Kaung Htet Kyaw",
            profileImage: ""
        }
    },
    {
        _id: "12",
        likedUser: {
            _id: "12",
            username: "Soe Thet Paing Htwe",
            profileImage: ""
        }
    },
    {
        _id: "13",
        likedUser: {
            _id: "13",
            username: "Kaung Htet Kyaw",
            profileImage: ""
        }
    },
    {
        _id: "14",
        likedUser: {
            _id: "14",
            username: "Soe Thet Paing Htwe",
            profileImage: ""
        }
    },
    {
        _id: "15",
        likedUser: {
            _id: "15",
            username: "Kaung Htet Kyaw",
            profileImage: ""
        }
    },
    {
        _id: "16",
        likedUser: {
            _id: "16",
            username: "Soe Thet Paing Htwe",
            profileImage: ""
        }
    },
]