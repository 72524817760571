import { Layout } from "antd";
import React from "react";
import { LayoutWrapperSection } from "./styles";
import { useMobileDimensions } from "@/hooks";

const { Content, Footer } = Layout;

const LayoutWrapper = ({ children, collapsed }) => {
  const { isMobile } = useMobileDimensions();
  return (
    <Content
      style={{
        overflow: "auto",
        marginTop: 70,
        marginLeft: isMobile ? 0 : collapsed ? 90 : 240,
        transition: "margin-left 0.3s ease",
        marginRight: 10,
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        minHeight: "100%",
      }}>
      <LayoutWrapperSection>{children}</LayoutWrapperSection>
      <Footer
        style={{
          textAlign: "center",
          background: "#eee",
          borderBottomRightRadius: 16,
          borderBottomLeftRadius: 16,
          justifySelf: "flex-end",
        }}>
        ©{new Date().getFullYear()} Created by Ulsancham
      </Footer>
    </Content>
  );
};

export default LayoutWrapper;
