import { Button, Flex, Input, message, Modal } from "antd";
import React from "react";

const { TextArea } = Input;

const AddTextModal = ({ open, setOpen, sectionId, onAdd }) => {
  const [text, setText] = React.useState("");

  const handleAddText = () => {
    if (text.length === 0) {
      return message.warning("Please enter a text");
    }
    onAdd({ text }); // Call onAdd with the entered text
    setText("");
    setOpen(false); // Close the modal after saving
  };

  return (
    <Modal title="Add text" open={open} onCancel={() => setOpen(false)} centered footer={null}>
      <Flex vertical gap={10}>
        <TextArea
          size={"large"}
          placeholder="Enter text"
          value={text}
          onChange={e => setText(e.target.value)}
        />

        <Button type="primary" onClick={handleAddText}>
          Add Text
        </Button>
      </Flex>
    </Modal>
  );
};

export default AddTextModal;
