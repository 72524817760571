import { Button, Flex, Image, List, Modal, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useSelector } from "react-redux";
import BlogContentImage from "./BlogContentImage";
import BlogContentVideo from "./BlogContentVideo";
import BlogContentLink from "./BlogContentLink";
import BlogContentQuote from "./BlogContentQuote";
import BlogCreateButton from "./BlogCreateButton";
import { nanoid } from "@reduxjs/toolkit";
import { CImage } from "@/App/common";

const { Text } = Typography;

const BlogPreviewModal = ({ open, setOpen }) => {
  const { title, coverImage, contentSections, category } = useSelector(
    (state: any) => state.blogForm
  );
  return (
    <Modal
      centered
      open={open}
      title="Blog Preview"
      onCancel={() => setOpen(false)}
      style={{
        minWidth: "80vw",
      }}
      styles={{
        body: {
          maxHeight: "80vh",
          maxWidth: "100%",
          overflow: "auto",
        },
      }}
      footer={[
        <Button key={nanoid()} onClick={() => setOpen(false)}>
          Back to edit
        </Button>,
        <BlogCreateButton key={nanoid()} setOpen={setOpen} />,
      ]}>
      <Title level={3}>Title: {title}</Title>
      <Title level={3}>Category: {category}</Title>
      <Title level={3}>Cover Image</Title>
      {coverImage && <CImage src={coverImage?.data_url} width={200} height={200} />}
      <Title
        level={3}
        style={{
          marginTop: "1.2rem",
        }}>
        Sections
      </Title>
      <List
        dataSource={contentSections}
        bordered
        itemLayout="vertical"
        renderItem={(section: any, index) => {
          return (
            <List.Item key={nanoid()}>
              <Flex
                vertical
                style={{
                  marginBottom: 10,
                }}>
                <Title level={3}>{`Section ${index + 1}`}</Title>
                <Text style={{ fontSize: 16 }}>{section.caption}</Text>
              </Flex>
              <List
                style={{
                  width: "100%",
                }}
                dataSource={section?.content}
                bordered
                renderItem={(content: any) => (
                  <List.Item key={nanoid()}>
                    {content.type === "TEXT" && (
                      <p style={{ fontSize: 16 }}>{content.description}</p>
                    )}
                    {content.type === "IMAGE" && <BlogContentImage content={content} />}
                    {content.type === "VIDEO" && <BlogContentVideo content={content} />}
                    {content.type === "LINK" && <BlogContentLink content={content} />}
                    {content.type === "QUOTE" && <BlogContentQuote content={content} />}
                  </List.Item>
                )}
              />
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};

export default BlogPreviewModal;
