import { CModal } from "@/App/common";
import { BlogModalUserList } from "@/App/features";
// import { GET_USERS } from "@/graphql";
// import { useQuery } from "@apollo/client";
import { Input } from "antd";
import React, { useState } from "react";

const { Search } = Input;

const BlogUserSelectModal = ({ open, setOpen }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const limit = 10;

  // const {
  //   data: users,
  //   loading,
  //   fetchMore,
  // } = useQuery(GET_USERS, {
  //   variables: {
  //     offset: 0,
  //     limit: limit,
  //     search: searchQuery,
  //   },
  // });

  const onSearch = (value, _e, info) => {
    setSearchQuery(value);
  };
  return (
    <CModal
      open={open}
      title="Users"
      onCancel={() => setOpen(false)}
      centered
      footer={null}
      width={"60%"}
      bodyStyle={{
        scrollbarWidth: "none",
      }}>
      <Search placeholder="Search here" onSearch={onSearch} style={{ width: "100%" }} />
      <BlogModalUserList
        // categories={users}
        // loading={loading}
        // fetchMore={fetchMore}
        limit={limit}
        setOpen={setOpen}
      />
    </CModal>
  );
};

export default BlogUserSelectModal;
