// import { useEffect } from "react";
// import { throttle } from "lodash";
// import { atom, useAtom } from "jotai";
// import { breakpoints } from "@/constant/ui-constant";

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   const isMobile = /iphone|ipad|ipod|android|mobile/i.test(navigator.userAgent?.toLowerCase());
//   return {
//     width,
//     height,
//     isMobile: width <= breakpoints.mobile || isMobile,
//   };
// }
// const windowDimensionsAtom = atom(getWindowDimensions());

// export default function useMobileDimensions() {
//   const [windowDimensions, setWindowDimensions] = useAtom(windowDimensionsAtom);

//   useEffect(() => {
//     const handleResize = () => {
//       setWindowDimensions(getWindowDimensions());
//     };

//     // Throttle the function to improve performance
//     const throttledHandleResize = throttle(handleResize, 100);

//     window.addEventListener("resize", throttledHandleResize);

//     // Cleanup the event listener on component unmount
//     return () => window.removeEventListener("resize", throttledHandleResize);
//   }, [setWindowDimensions]);

//   return windowDimensions;
// }
import { breakpoints } from "@/constant/ui-constant";
import { useMediaQuery } from "react-responsive";

const useMobileDimensions = () => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.mobile });
  return { isMobile };
};

export default useMobileDimensions;
