import React from "react";
import styled from "styled-components";

const StyledContent = styled.div<{
  $minheight: string;
  $maxheight?: string;
  $paddingblock: string;
  $paddinginline: string;
  $backgroundcolor: string;
}>`
  width: 100%;
  min-height: ${props => props.$minheight};
  max-height: ${props => props.$maxheight};
  padding-block: ${props => props.$paddingblock};
  padding-inline: ${props => props.$paddinginline};
  position: relative;
  background-color: ${props => props.$backgroundcolor};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

interface Props {
  minheight?: string;
  maxheight?: string;
  paddingblock?: string;
  paddinginline?: string;
  backgroundcolor?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const CContent = ({
  children,
  style,
  minheight = "80vh",
  maxheight,
  paddingblock = "0.5rem",
  paddinginline = "0.5rem",
  backgroundcolor = "rgba(255, 255, 255, 0.8)",
}: Props) => {
  return (
    <StyledContent
      style={style}
      $minheight={minheight}
      $maxheight={maxheight}
      $paddingblock={paddingblock}
      $paddinginline={paddinginline}
      $backgroundcolor={backgroundcolor}>
      {children}
    </StyledContent>
  );
};

export default CContent;
