import { Button, Flex, Input, Modal } from "antd";
import React, { useEffect } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { VideoCameraAddOutlined } from "@ant-design/icons";
import { CText } from "@/App/common";
import { convertFileToBase64 } from "@/utilities/utilities";

const { TextArea } = Input;

const EditVideoModal = ({ open, setOpen, sectionId, content, updateContent }) => {
  const [description, setDescription] = React.useState(content?.description || "");
  const [video, setVideo] = React.useState<any>(JSON.parse(content?.text) || null);
  useEffect(() => {
    setVideo(JSON.parse(content?.text) || null);
  }, [sectionId, content?.id]);

  const onVideoChange = (imageList: ImageListType) => {
    setVideo(imageList[0]);
  };

  const handleEditVideo = async () => {
    if (video === null) {
      updateContent(sectionId, content?.id, {
        text: "",
        description: "",
      });
    }

    const file = video.file;

    try {
      const base64String = await convertFileToBase64(file);

      const fileString = JSON.stringify({
        file: {
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
        },
        data_url: video.data_url || base64String,
      });
      updateContent(sectionId, content?.id, {
        text: fileString,
        description,
      });

      setOpen(false);
    } catch (error: any) {
      console.error("Error converting file to Base64:", error.message);
    }
  };

  return (
    <Modal title="Edit Video" open={open} onCancel={() => setOpen(false)} centered footer={null}>
      <Flex vertical gap={20}>
        {video?.data_url && (
          <Flex justify="space-between">
            <video width="100%" height="240" controls>
              <source src={video?.data_url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              type="primary"
              danger
              onClick={() => {
                setVideo(null);
              }}>
              Remove
            </Button>
          </Flex>
        )}
        <ImageUploading
          acceptType={["mp4", "webm", "ogg", "mov"]}
          value={[]}
          allowNonImageType={true}
          onChange={onVideoChange}
          onError={(e, f) => {
            console.log("Error type = ", e);
            console.log("file", f);
          }}
          multiple={false}
          dataURLKey="data_url"
          maxNumber={1}>
          {({ onImageUpload }) => (
            <Flex
              className="upload__image-wrapper"
              style={{
                border: "1px dashed #6B6B6B",
                padding: 10,
                borderRadius: 5,
                width: "fit-content",
                cursor: "pointer",
              }}
              align="center"
              vertical
              onClick={onImageUpload}>
              <VideoCameraAddOutlined style={{ fontSize: 22, color: "#6B6B6B" }} />
              <CText>Upload</CText>
            </Flex>
          )}
        </ImageUploading>
        <TextArea
          placeholder="Enter video description"
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <Button type="primary" onClick={handleEditVideo}>
          Edit Video
        </Button>
      </Flex>
    </Modal>
  );
};

export default EditVideoModal;
