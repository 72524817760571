import React from "react";
import { LoginPage } from "./LazyPages";

const authRoutes = [
  {
    path: "/login",
    element: <LoginPage />,
    protected: false,
  },
];

export default authRoutes;
