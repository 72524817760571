import styled from "styled-components";

export const StyledUserCoverImage = styled.div`
  height: 12rem;
  display: block;
  background-color: #be5add;
  opacity: 0.4;
`;

export const StyledUserInfo = styled.section`
  display: grid;
  & > * + * {
    margin-top: 1rem;
  }
`;

export const StyledItemWrapper = styled.div<{isMobile: boolean}>`
  display: grid;
  width: ${(props) => props.isMobile ? '100%' : '500px'};
  & > * + * {
    margin-top: 1rem;
  }
`;

export const StyledItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  & > :first-child {
    justify-self: flex-start;
  }
  & > :nth-child(2) {
    justify-self: self-start;
  }
  & > :nth-child(3) {
    justify-self: self-start;
  }
`;
