import { CText } from "@/App/common";
import { convertFileToBase64 } from "@/utilities/utilities";
import { FileImageOutlined } from "@ant-design/icons";
import { Button, Flex, Image, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";

const { TextArea } = Input;

const EditImageModal = ({ open, setOpen, sectionId, content, updateContent }) => {
  const [description, setDescription] = useState(content?.description || "");
  const safeParse = text => {
    try {
      return JSON.parse(text);
    } catch {
      return null; // Return null if parsing fails
    }
  };
  const [image, setImage] = useState(() => safeParse(content?.text) || null);
  useEffect(() => {
    setImage(safeParse(content?.text));
  }, [content?.text, sectionId]);
  const onImageChange = (imageList: ImageListType) => {
    setImage(imageList[0]);
  };

  const handleUpdateImage = async () => {
    if (image === null) {
      return message.warning("Please upload an image");
    }

    // If using a library like react-images-uploading, ensure you access the `file` property
    const file = image.file || image; // Adjust based on your `image` structure

    try {
      const base64String = await convertFileToBase64(file);

      const fileString = JSON.stringify({
        file: {
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
        },
        data_url: base64String,
      });
      updateContent(sectionId, content?.id, {
        text: fileString,
        description,
      });

      setOpen(false);
    } catch (error: any) {
      console.error("Error converting file to Base64:", error.message);
    }
  };

  return (
    <Modal title="Edit Image" open={open} onCancel={() => setOpen(false)} centered footer={null}>
      <Flex vertical gap={20}>
        {image?.data_url && (
          <Flex justify="space-between">
            <Image
              src={image.data_url}
              alt="image"
              style={{ objectFit: "cover" }}
              width={200}
              height={200}
            />
            <Button
              type="primary"
              danger
              onClick={() => {
                setImage(null);
              }}>
              Remove
            </Button>
          </Flex>
        )}
        <ImageUploading
          acceptType={["jpg", "jpeg", "png", "gif"]}
          value={[]}
          onChange={onImageChange}
          onError={e => {
            console.log("Error type = ", e);
          }}
          multiple={false}
          dataURLKey="data_url"
          maxNumber={1}>
          {({ onImageUpload }) => (
            <Flex
              className="upload__image-wrapper"
              style={{
                border: "1px dashed #6B6B6B",
                padding: 10,
                borderRadius: 5,
                width: "fit-content",
                cursor: "pointer",
              }}
              align="center"
              vertical
              onClick={onImageUpload}>
              <FileImageOutlined style={{ fontSize: 22, color: "#6B6B6B" }} />
              <CText>Upload</CText>
            </Flex>
          )}
        </ImageUploading>
        <TextArea
          placeholder="Enter image description"
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <Button type="primary" onClick={handleUpdateImage}>
          Edit Image
        </Button>
      </Flex>
    </Modal>
  );
};

export default EditImageModal;
