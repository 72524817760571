import { USER_SESSION } from "@/constant/session-key";

export const storeUserSession = async userSession => {
  try {
    await localStorage.setItem(USER_SESSION, JSON.stringify(userSession));
  } catch (error) {
    console.log("Error saving user session to encrypted storage:", error);
  }
};

export const removeUserSession = async () => {
  try {
    await localStorage.removeItem(USER_SESSION);

    console.log("User session removed from encrypted storage");
  } catch (error) {
    console.log("Error removing user session from encrypted storage:", error);
  }
};
