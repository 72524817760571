import styled from "styled-components";

export const StyledWrapper = styled.div<{ $isMobile: boolean }>`
  height: 100%;
  display: grid;
  grid-template-columns: ${props => (props.$isMobile ? "1fr" : "1fr 2fr")};
  border-radius: 0.5rem;
  overflow: hidden;
  & > *:not(:last-child) {
    border-right: 1px solid #e0dbdb;
  }
`;

export const StyledRoomWrapper = styled.div`
  > * {
    height: 100%;
    position: relative;
  }
`;

export const StyledInfoWrapper = styled.div`
  > * {
    height: 98%;
    position: relative;
  }
`;