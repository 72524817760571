import { Checkbox, Form } from "antd";
import React from "react";

const ServiceFeatured = ({ form }) => {
  // Handle the Checkbox change event
  const handleChange = e => {
    form.setFieldsValue({ featured: e.target.checked });
  };

  return (
    <Form.Item
      label="Featured (Display on homepage)"
      name="featured"
      labelCol={{ span: 24 }}
      style={{ width: "100%", position: "relative" }}
      // rules={[{ required: true, message: "Please select active featured." }]}
      valuePropName="checked" // Link checkbox to Form's "checked" state
    >
      <div style={{ marginTop: -20 }}>
        <Checkbox onChange={handleChange} style={{}}>
          Featured
        </Checkbox>
      </div>
    </Form.Item>
  );
};

export default ServiceFeatured;
