import { CModal, CParagraph } from "@/App/common";
import { CreateServiceForm } from "@/App/forms";
import EditServiceForm from "@/App/forms/EditServiceForm";
import { ServiceIcon } from "@/assets/svg";
import { useMobileDimensions } from "@/hooks";
import { useToken } from "@/hooks/token";
import { useApiCreate } from "@/server/api-fetch";
import { DELETE_SERVICE } from "@/server/apis/service";
import { cleanPathAfterDelete, setServiceState } from "@/stores/Slices/serviceSlice";
import { Text, View } from "@/styles/reactnative";
import { Modal, Button, Flex, message } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
const { confirm } = Modal;

export const Container = styled.div<{ $isMobile: boolean }>`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #d7d7ea;
  justify-content: space-between;
  border-radius: 12px;
`;
const ServiceCard = ({ item, refetch }) => {
  const dispatch = useDispatch();
  const { user } = useToken();
  const { isMobile } = useMobileDimensions();
  const [visible, setVisible] = React.useState(false);

  const { mutate } = useApiCreate({
    url: DELETE_SERVICE,
    action: data => {
      if (data?.success) {
        message.success(data?.message || "Service updated successfully");
        dispatch(cleanPathAfterDelete(item?._id));
      } else {
        message.error(data?.message || "Failed to update service");
      }
    },
  });

  const onHandleDelete = () => {
    mutate({
      memberId: user?._id,
      serviceId: item?._id,
    });
  };
  return (
    <Container $isMobile={isMobile} key={item?._id}>
      <Flex gap={8}>
        <ServiceIcon />
        <Text style={{ fontSize: isMobile ? 10 : 16, fontWeight: "600", color: "#485AFF" }}>
          ဝန်ဆောင်မှု
        </Text>
      </Flex>
      <Text style={{ fontSize: isMobile ? 16 : 20, fontWeight: "500", color: "#000" }}>
        {item?.title}
      </Text>
      <CParagraph
        lines={2}
        style={{
          fontSize: isMobile ? 10 : 16,
          fontWeight: "400",
          color: "#4D4D4D",
          paddingBlock: isMobile ? 1 : 2,
        }}>
        {item?.description}
      </CParagraph>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}>
        <a
          onClick={() => {
            dispatch(setServiceState({ parentId: item?._id, title: item?.title }));
          }}
          style={{
            fontSize: isMobile ? 12 : 16,
            fontWeight: "600",
            textAlign: "right",
            color: "#485AFF",
          }}>
          အသေးစိတ်ကြည့်ရှုရန်
        </a>
        <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
          <Button
            onClick={() => {
              confirm({
                title: "Are you sure you want to delete this service?",
                onOk: onHandleDelete,
                onCancel() {
                  message.info("Deletion cancelled");
                },
              });
            }}
            style={{
              borderRadius: 100,
              padding: "14px 24px",
              border: "1px solid #000",
            }}>
            Delete
          </Button>
          <Button
            onClick={() => {
              setVisible(true);
            }}
            style={{
              borderRadius: 100,
              padding: "14px 24px",
              border: "1px solid #000",
            }}>
            Edit
          </Button>
        </View>
      </View>
      <CModal
        centered
        title="Filter Business Owner"
        footer={null}
        open={visible}
        width={isMobile ? "100%" : "30%"}
        bodyStyle={{
          maxHeight: "80vh",
          overflowY: "auto",
          scrollbarWith: "none",
          padding: 20,
        }}
        onCancel={() => {
          setVisible(false);
        }}>
        <EditServiceForm
          item={item}
          action={() => {
            setVisible(false);
            refetch?.();
          }}
        />
      </CModal>
    </Container>
  );
};

export default ServiceCard;
