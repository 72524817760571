import { combineReducers } from "redux";
import {
  usersReducer,
  appStoreReducer,
  appNotStoreReducer,
  socketReducer,
  serviceReducer,
  blogFormReducer,
} from "./Slices";
export const rootReducer = combineReducers({
  user: usersReducer,
  appstore: appStoreReducer,
  appnotstore: appNotStoreReducer,
  socket: socketReducer,
  service: serviceReducer,
  blogForm: blogFormReducer,
});
