import React from "react";
import { Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";

const { Paragraph } = Typography;

const CText = ({ children, ...props }: TextProps) => {
  return (
    <Paragraph
      {...props}
      style={{
        fontSize: 16,
        whiteSpace: "pre-wrap",
        margin: 0,
        ...props.style,
      }}>
      {children}
    </Paragraph>
  );
};

export default CText;
