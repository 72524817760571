import styled from "styled-components";

export const PageWrapperSection = styled.div`
  width: 100%;
  overflow: auto;
  align-self: center;
  height: 100vh;
  margin: auto;
  background-color: ${p => p.theme.background};
  font-family: "Noto Sans KR", "Noto Sans Korean", "Nanum Gothic";
  &::-webkit-scrollbar {
    width: 0;
  }
`;
