import React from "react";
import Routers from "@/App/routes";
import { themeColor } from "@/styles/themeColor";
import isPropValid from "@emotion/is-prop-valid";
import { useSelector } from "react-redux";
import { ThemeProvider as StyledThemeProvider, StyleSheetManager } from "styled-components";
import { ConfigProvider, message } from "antd";

const App = () => {
  const darkMode = useSelector((state: any) => state?.appstore?.darkMode);
  const isDarkMode = darkMode ? themeColor?.dark : themeColor?.light;

  message.config({
    top: 30,
    duration: 2,
    maxCount: 1,
    rtl: true,
    prefixCls: "c-toast",
  });

  return (
    <ConfigProvider>
      <StyledThemeProvider theme={isDarkMode}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <Routers />
        </StyleSheetManager>
      </StyledThemeProvider>
    </ConfigProvider>
  );
};
function shouldForwardProp(propName: any, target: any) {
  if (typeof target === "string") {
    return isPropValid(propName);
  }
  return true;
}
export default App;
