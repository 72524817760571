import { Button, Flex, Input, message, Modal } from "antd";
import React from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { VideoCameraAddOutlined } from "@ant-design/icons";
import { CText } from "@/App/common";
import { convertFileToBase64 } from "@/utilities/utilities";

const { TextArea } = Input;

const AddVideoModal = ({ open, setOpen, sectionId, onAdd }) => {
  const [description, setDescription] = React.useState("");
  const [video, setVideo] = React.useState<any>(null);

  const onVideoChange = (imageList: ImageListType) => {
    // console.log(imageList, addUpdateIndex);
    setVideo(imageList[0]);
  };

  const handleAddVideo = async () => {
    if (video === null) {
      return message.warning("Please enter a video");
    }
    // If using a library like react-images-uploading, ensure you access the `file` property
    const file = video.file || video; // Adjust based on your `image` structure

    try {
      const base64String = await convertFileToBase64(file);

      const fileString = JSON.stringify({
        file: {
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          webkitRelativePath: file.webkitRelativePath || "",
          lastModifiedDate: file.lastModifiedDate,
        },
        data_url: base64String,
      });

      onAdd({ data: fileString, description }); // Call onAdd with the entered text
      setDescription("");
      setVideo(null);
      setOpen(false); // Close the modal after saving
    } catch (error: any) {
      console.error("Error converting file to Base64:", error.message);
    }
  };

  return (
    <Modal title="Add Video" open={open} onCancel={() => setOpen(false)} centered footer={null}>
      <Flex vertical gap={20}>
        {video && (
          <Flex justify="space-between">
            <video width="100%" height="240" controls>
              <source src={video.data_url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              type="primary"
              danger
              onClick={() => {
                setVideo(null);
              }}>
              Remove
            </Button>
          </Flex>
        )}
        <ImageUploading
          acceptType={["mp4", "webm", "ogg", "mov"]}
          value={[]}
          allowNonImageType={true}
          onChange={onVideoChange}
          onError={(e, f) => {
            console.log("Error type = ", e);
            console.log("file", f);
          }}
          multiple={false}
          dataURLKey="data_url"
          maxNumber={1}>
          {({ onImageUpload }) => (
            <Flex
              className="upload__image-wrapper"
              style={{
                border: "1px dashed #6B6B6B",
                padding: 10,
                borderRadius: 5,
                width: "fit-content",
                cursor: "pointer",
              }}
              align="center"
              vertical
              onClick={onImageUpload}>
              <VideoCameraAddOutlined style={{ fontSize: 22, color: "#6B6B6B" }} />
              <CText>Upload</CText>
            </Flex>
          )}
        </ImageUploading>
        <TextArea
          placeholder="Enter video description"
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <Button type="primary" onClick={handleAddVideo}>
          Add Video
        </Button>
      </Flex>
    </Modal>
  );
};

export default AddVideoModal;
