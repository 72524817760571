import { Grid, Typography, theme } from "antd";
import React from "react";
import LoginForm from "./LoginForm";

const { useBreakpoint } = Grid;
const { useToken } = theme;
const { Text, Title } = Typography;

const LoginSection = () => {
  const screens = useBreakpoint();
  const { token } = useToken();
  return (
    <section
      style={{
        alignItems: "center",
        backgroundColor: "transparent",
        display: "flex",
        height: screens.sm ? "100vh" : "auto",
      }}>
     <div
        style={{
          margin: "0 auto",
          padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
          width: "380px",
        }}>
        <div style={{ marginBottom: token.marginXL }}>
          <Title
            style={{
              fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
              color: "#fff",
            }}>
            Login
          </Title>
          <Text style={{ color: "#fff" }}>Welcome to Ulsancham Admin Dashboard</Text>
        </div>
        <LoginForm />
      </div>
    </section>
  );
};

export default LoginSection;
