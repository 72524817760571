import React, { useEffect, useState } from "react";
import { CreateServiceFormSection } from "./styles";
import { Form, message } from "antd";
import { useApiCreate, useApiQuery } from "@/server/api-fetch";
import { EDIT_SERVICE, GET_SERVICE_BY_PARENT_ID } from "@/server/apis/service";
import { CButton } from "@/App/common";
import ServiceFeatured from "./ServiceFeatured";
import ServiceTitle from "./ServiceTitle";
import ServiceDescription from "./ServiceDescription";
import ServiceParentSelect from "./ServiceParentSelect";
const EditServiceForm = ({ item, action }) => {
  const [form] = Form.useForm();
 
  const { data: otherServices, refetch } = useApiQuery({
    url: `${GET_SERVICE_BY_PARENT_ID}?parentId=&search=&serviceId=${item?._id}`,
  });
  const [parent, setParent] = useState({
    _id: otherServices?.[0]?._id || "",
    title: otherServices?.[0]?.title || "",
  });
  useEffect(() => {
    if (otherServices?.length > 0) {
      setParent({
        _id: otherServices?.[0]?._id || "",
        title: otherServices?.[0]?.title || "",
      });
    }
  }, [otherServices]);
  useEffect(() => {
    refetch?.();
  }, [refetch]);

  const { mutate } = useApiCreate({
    url: EDIT_SERVICE,
    action: data => {
      if (data?.success) {
        message.success(data?.message || "Service updated successfully");
        action?.();
        form.resetFields();
      } else {
        message.error(data?.message || "Failed to update service");
      }
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      title: item?.title,
      description: item?.description,
      featured: item?.featured,
      parentId: otherServices?.[0]?.title,
    });
  }, [otherServices]);
  const onFinish = async values => {
    mutate({
      title: values.title,
      description: values.description,
      serviceId: item?._id,
      featured: values.featured,
      parentId: parent?._id,
    });
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed: ", errorInfo);
  };
  return (
    <CreateServiceFormSection>
      <Form
        variant="filled"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <ServiceParentSelect otherServices={otherServices} setParent={setParent} />
        <ServiceTitle error="" />
        <ServiceDescription error="" />
        <ServiceFeatured form={form} />

        <Form.Item style={{ justifyContent: "flex-end", display: "flex" }}>
          <CButton htmlType="submit" type="primary" style={{ marginRight: 10 }}>
            Save
          </CButton>
          <CButton htmlType="reset" onClick={() => {}}>
            Reset
          </CButton>
        </Form.Item>
      </Form>
    </CreateServiceFormSection>
  );
};

export default EditServiceForm;
