import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";

const { TextArea } = Input;

const BlogSectionItemCaption = ({ item, updateCaption }) => {
  const [caption, setCaption] = useState(item?.caption || "");

  useEffect(() => {
    setCaption(item?.caption || "");
  }, [item?.caption]);

  const handleCaptionChange = e => {
    const newCaption = e.target.value;
    setCaption(newCaption);
    updateCaption(item?._id, newCaption);
  };

  return (
    <Form.Item label="Caption" labelCol={{ span: 24 }} style={{ width: "100%" }}>
      <TextArea
        value={caption}
        rows={4}
        placeholder="Write a caption here"
        onChange={handleCaptionChange}
      />
    </Form.Item>
  );
};

export default BlogSectionItemCaption;
