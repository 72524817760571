import { Layout, Menu } from "antd";
import React from "react";
import {
  UserOutlined,
  BookOutlined,
  ContainerOutlined,
  CompressOutlined,
  PieChartOutlined,
  MessageOutlined,
  ProductOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useMobileDimensions } from "@/hooks";
import { useDispatch } from "react-redux";
import { setItem } from "@/stores/Slices/usersSlice";

const { Sider } = Layout;

const DashboardDrawer = ({ collapsed, activeKey, setActiveKey, setCollapsed }) => {
  const { isMobile } = useMobileDimensions();

  const dispatch = useDispatch();
  const items = [
    {
      icon: <PieChartOutlined style={{ fontSize: "18px" }} />,
      key: "Dashboard",
      label: "Dashboard",
    },
    {
      icon: <UserOutlined style={{ fontSize: "18px" }} />,
      key: "Users",
      label: "Users",
    },
    {
      icon: <BookOutlined style={{ fontSize: "18px" }} />,
      key: "Blogs",
      label: "Blogs",
    },
    {
      icon: <MessageOutlined style={{ fontSize: "18px" }} />,
      key: "Chats",
      label: "Chats",
    },
    {
      icon: <ProductOutlined style={{fontSize: "18px"}} />,
      key: "Products",
      label: "Products"
    },
    {
      icon: <CompressOutlined style={{fontSize: "18px"}} />,
      key: "Business",
      label: "Business"
    },
    {
      icon: <ContainerOutlined style={{ fontSize: "18px" }} />,
      key: "Service",
      label: "Service",
    },
    {
      icon: <MailOutlined style={{ fontSize: "18px" }} />,
      key: "Report",
      label: "Report",
    },
    {
      icon: <CompressOutlined style={{ fontSize: "18px" }} />,
      key: "Categories",
      label: "Categories",
    },
    {
      icon: <CompressOutlined style={{ fontSize: "18px" }} />,
      key: "Form",
      label: "Form",
    },
  ];

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={isMobile ? false : collapsed}
      width={isMobile ? (collapsed ? 230 : 0) : 230}
      theme="light"
      style={{
        overflow: "auto",
        position: "fixed",
        left: 0,
        top: 65,
        bottom: 0,
        zIndex: 2,
      }}>
      <Menu
        theme="light"
        defaultSelectedKeys={[activeKey]}
        mode="inline"
        items={items}
        onClick={({ key }) => {
          setActiveKey(key);
          dispatch(setItem({ activeKey: key }));
          setCollapsed(false);
        }}
      />
    </Sider>
  );
};

export default DashboardDrawer;
