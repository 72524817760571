import { useApiCreate } from "@/server/api-fetch";
import { LOGIN_ADMIN } from "@/server/apis";
import { loginForm } from "@/stores/Slices/appstoreSlice";
import { anyTruthy } from "@/utilities/utilities";
import { LockOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmCode from "./ConfirmCode";

const LoginForm = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state: any) => state.appstore.loggedIn);
  const [phone, setPhone] = useState(loggedIn.phone);
  const [password, setPassword] = useState(loggedIn.password);
  const [loggedSuccess, setLoggedSuccess] = useState(false);

  const { mutate } = useApiCreate({
    url: LOGIN_ADMIN,
    action: data => {
      if (data?.success) {
        message.success(data?.message || "Login success");
        setLoggedSuccess(true);
      } else {
        message.error(data?.message || "Login failed");
      }
    },
  });
  const onFinish = async () => {
    if (anyTruthy(phone)) {
      if (anyTruthy(password)) {
        mutate({
          phone: phone,
          password: password,
        });
      } else {
        message.info("Required Password!");
      }
    } else {
      message.info("Required Phone!");
    }
  };

  return (
    <>
      <ConfirmCode
        phone={phone}
        loggedSuccess={loggedSuccess}
        setLoggedSuccess={setLoggedSuccess}
      />
      <Form
        name="normal_login"
        initialValues={{}}
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional">
        <Form.Item
          name="phone"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please input your Phone!",
            },
          ]}>
          <Input
            value={phone}
            onChange={e => {
              setPhone(e.target.value);
            }}
            prefix={<PhoneOutlined />}
            placeholder="Phone"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}>
          <Input.Password
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember">
            <Checkbox
              checked={loggedIn.checked}
              onChange={() => {
                dispatch(
                  loginForm({
                    checked: !loggedIn.checked,
                    phone: phone,
                    password: password,
                  })
                );
              }}>
              <p style={{ color: "#fff" }}> Remember me</p>
            </Checkbox>
          </Form.Item>
          <a
            style={{
              float: "right",
              color: "#fff",
            }}
            href="">
            Forgot password?
          </a>
        </Form.Item>
        <Form.Item style={{ marginBottom: "0px" }}>
          <Button block={true} type="primary" htmlType="submit">
            Log in
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginForm;
