import { Button, Flex, Input, message, Modal } from "antd";
import React from "react";

const { TextArea } = Input;

const EditTextModal = ({ open, setOpen, sectionId, content, updateContent }) => {
  const [text, setText] = React.useState(content?.text || "");

  const handleSave = () => {
    if (text.length === 0) {
      return message.warning("Please enter a text");
    }
    if (updateContent) {
      updateContent(sectionId, content?.id, { text });
    }
    setOpen(false);
  };

  return (
    <Modal title="Edit quote" open={open} onCancel={() => setOpen(false)} centered footer={null}>
      <Flex vertical gap={10}>
        <TextArea
          size={"large"}
          placeholder="Enter text"
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <Button type="primary" onClick={handleSave}>
          Edit text
        </Button>
      </Flex>
    </Modal>
  );
};

export default EditTextModal;
