import { Form, Input } from "antd";
import React from "react";

const { TextArea } = Input;

const CategoryDescription = () => {
  return (
    <Form.Item
      label="Description"
      name="description"
      labelCol={{ span: 24 }}
      style={{ width: "100%" }}>
      <TextArea rows={4} placeholder="Type your category's description here" name="description" />
    </Form.Item>
  );
};

export default CategoryDescription;
