import styled from "styled-components";

import { Layout } from "antd";

const { Header } = Layout;
export const DashboardHeaderSection = styled(Header)`
  background-color: #fff;
  padding: 0;
  background: "#fff";
  position: absolute;
  overflow: auto;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  transition: margin-left 0.3s ease;
  z-index: 1000;
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 20px;
  z-index: 1050; /* Higher than the header */
`;

export const DropdownContent = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-height: 400px;
  overflow-y: auto;
  z-index: 9999 !important;
  width: 250px;
  padding: 12px;
`;

export const DropdownIcon = styled.div`
  cursor: pointer;
  margin-right: 20px;
`;

export const DropdownText = styled.div`
  cursor: pointer;
  margin-right: 20px;
`;

export const StyledHeader = styled(Layout.Header)<{ $ismobile: boolean; $collapsed: boolean }>`
  height: ${props => (props.$ismobile ? "52px" : "64px")};
  margin-left: ${props => (props.$ismobile ? 0 : props.$collapsed ? "90px" : "240px")};
  padding-block: 0;
  padding-inline: 0.6rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  background: hsl(0 12% 100% / 0.2);
  border-radius: 8px;
  z-index: 1000;
  backdrop-filter: blur(8px);
  transition: margin-left 0.2s ease-in-out;

  & > * {
    height: 100%;
  }
`;