import React from "react";
import { Button } from "antd";

const CButton = ({ onClick, children, ...props }: any) => {
  return (
    <Button onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

export default CButton;
