// Admin Auth System
export const LOGIN_ADMIN = "/auth/loginAdmin";
export const LOGOUT_ADMIN = "/auth/logoutAdmin";
export const ADMIN_CONFIRM_VERIFY = "/auth/adminConfirmVerification";
// User Auth System
export const LOGIN = "/auth/login";
export const CONFIRM_VERIFICATION = "/auth/confirmVerification";

export const GET_ALL_USERS = "/users/getAllUsers";
export const GET_USER = "/users/getUser";
export const EDIT_USER = "/users/editUser";
