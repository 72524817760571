import { CloseCircleFilled } from "@ant-design/icons";
import { Avatar, Flex, Input, Typography } from "antd";
import styled from "styled-components";

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledHeader = styled.div`
  width: 100%;
  height: 55px;
  padding: 0;
  position: absolute;
  left: 0;
  padding-inline: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(163, 157, 157, 0.452);
`;

export const StyledText = styled(Typography.Text)`
  font-weight: 500;
`;

export const StyledMessagesWrapper = styled.div<{ $isMobile: boolean }>`
  min-height: ${props => (props.$isMobile ? "60vh" : "68vh")};
  max-height: ${props => (props.$isMobile ? "60vh" : "68vh")};
  margin-top: 3.5rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-inline: 0.5rem;
  scrollbar-width: none;
  overflow-y: scroll;
  & > * + * {
    margin-top: 0.5rem;
  }
`;

export const StyledMessage = styled.div<{ $isSender: boolean; $isMobile: boolean }>`
  max-height: 150px;
  display: flex;
  flex-direction: ${props => (props.$isSender ? "row-reverse" : "row")};
  gap: 0.5rem;
  margin-bottom: 10px;
`;
export const StyledMessageAvatar = styled(Avatar)`
  align-self: end;
`;
export const StyledMessageTime = styled.time<{ $isSender: boolean }>`
  color: ${props => (props.$isSender ? "#ffffff" : "#030303")};
  font-size: 0.7rem;
  opacity: 0.6;
`;
export const StyledMessageText = styled(Flex)<{ $isSender: boolean }>`
  max-width: 200px;
  padding-block: 0.3rem;
  padding-inline: 0.6rem;
  position: relative;
  border-radius: 8px;
  background-color: ${props => (props.$isSender ? "#1677ff" : "#f5f5f5")};
  color: ${props => (props.$isSender ? "#f5f9ff" : "#121010")};
`;

export const StyledInputWrapper = styled.div<{ $isDesktop: boolean }>`
  width: 100%;
  bottom: 0;
  padding-inline: 1rem;
  padding-block: 1rem;
  padding-bottom: ${props => (props.$isDesktop ? "2rem" : "1rem")};
  display: flex;
  background-color: #fff;
  gap: 0.5rem;
  border-top: 0.5px solid rgba(163, 157, 157, 0.452);
  justify-content: space-between;
  align-items: center;
  & > *:not(.no_shadow) {
    box-shadow: 0 1px 2px rgba(163, 157, 157, 0.452);
  }
  & > :first-child {
    flex: 1;
  }
`;

export const StyledTextarea = styled(Input.TextArea)`
  height: 1.5rem;
  background-color: #f8f8f8;
  border: none;
`;

export const StyledVideoThumbnail = styled.div`
  min-width: 80px;
  max-width: 80px;
  min-height: 60px;
  max-width: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 1px 2px rgba(163, 157, 157, 0.452);
`

export const StyledMediaImage = styled.div`
  min-width: 50px;
  max-width: 50px;
  min-height: 80px;
  max-height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`