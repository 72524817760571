import moment from "moment";

export const truncateString = (str: string, maxLength: number = 12) => {
  const truncatedStr = str?.slice(0, maxLength) + "...";
  return str?.length <= maxLength ? str : truncatedStr;
};
export const createFileFromMetadata = metadata => {
  const { name, type, data_url } = metadata;

  // Convert base64 to Blob
  const byteString = atob(data_url.split(",")[1]); // Decode base64
  const mimeString = data_url.split(",")[0].split(":")[1].split(";")[0]; // Extract MIME type

  const byteArray: any = [];
  for (let i = 0; i < byteString.length; i++) {
    byteArray.push(byteString.charCodeAt(i));
  }

  const blob = new Blob([new Uint8Array(byteArray)], { type: mimeString });

  // Return a new File object
  return new File([blob], name, { type });
};
export const convertFileToBase64 = file => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(new Error("The provided file is not a Blob or File."));
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file); // Converts file to Base64 string
    reader.onload = () => resolve(reader.result); // Resolves with Base64 string
    reader.onerror = error => reject(error);
  });
};
export const getFirstWord = (data, num) => {
  if (!data) {
    return "";
  }
  const words = data.trim().split(" ");
  if (words.length > 0) {
    return words[num];
  }
  if (words.length > 0) {
    return words[0];
  }

  return "";
};

export const getLimitedContent = (content, maxLength = 15) => {
  if (!content || content.length <= maxLength) {
    return content;
  } else {
    return content.substring(0, maxLength) + ""; // Append ellipsis if content exceeds maxLength
  }
};

export const anyTruthy = (...args: any[]): boolean => args.some(Boolean);
export const firstUpperChar = data => {
  return data?.charAt?.(0)?.toUpperCase?.() + data?.slice?.(1);
};
export const formatFileSize = bytes => {
  const megabytes = bytes / (1024 * 1024); // Convert bytes to MB
  return megabytes.toFixed(1) + " MB"; // Format to 1 decimal place
};
export const formatDuration = duration => {
  if (duration < 60) {
    return duration.toFixed(2) + " s";
  } else {
    const minutes = Math.floor(duration / 60);
    const seconds = (duration % 60).toFixed(2);
    return `${minutes} m ${seconds} s`;
  }
};

export const formattedDate = date => {
  const now = moment();
  const messageDate = moment.unix(date / 1000);

  // Calculate the time difference between now and the message date
  const diffInMinutes = now.diff(messageDate, "minutes");
  const diffInHours = now.diff(messageDate, "hours");
  const diffInDays = now.diff(messageDate, "days");
  const diffInMonths = now.diff(messageDate, "months");
  const diffInYears = now.diff(messageDate, "years");

  // Display different formats based on the time difference
  if (diffInMinutes < 60) {
    return `${diffInMinutes} min ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hr ago`;
  } else if (diffInDays < 30) {
    return `${diffInDays} d ago`;
  } else if (diffInMonths < 12) {
    return `${diffInMonths} mo ago`;
  } else {
    return `${diffInYears} yr ago`;
  }
};

export const ElapsedTime = (date: Date | any) => {
  if (date === null) {
    return "";
  }
  // seconds (milliseconds)
  const second = 1;
  // m (minutes)
  const minute = 60 * second;
  // h (hours)
  const hour = 60 * minute;
  // d (days)
  const day = 24 * hour;

  const today = new Date();
  const elapsedTime = Math.trunc((today.getTime() - date) / 1000);

  if (elapsedTime < minute) {
    return "Just now";
  } else if (elapsedTime < hour) {
    return `${Math.trunc(elapsedTime / minute)} minute${
      Math.trunc(elapsedTime / minute) === 1 ? "" : "s"
    } ago`;
  } else if (elapsedTime < day) {
    return `${Math.trunc(elapsedTime / hour)} hour${
      Math.trunc(elapsedTime / hour) === 1 ? "" : "s"
    } ago`;
  } else if (elapsedTime < 14 * day) {
    return `${Math.trunc(elapsedTime / day)} day${
      Math.trunc(elapsedTime / day) === 1 ? "" : "s"
    } ago`;
  } else {
    // Check if the date is over one year ago
    const oneYearAgo = moment().subtract(1, "year");
    if (moment(+date).isBefore(oneYearAgo)) {
      // If the date is over one year ago, format it as 'YYYY MMM DD'
      return moment(+date).format("YYYY MMM DD");
    } else {
      // If the date is within one year, format it as 'MMM DD'
      return moment.unix(date / 1000).format("MMM DD");
    }
  }
};
