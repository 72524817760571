import React from "react";
import { Space, Input, Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginData } from "@/stores/Slices/usersSlice";
import { storeUserSession } from "@/utilities/storageUserData";
import { useApiCreate } from "@/server/api-fetch";
import { ADMIN_CONFIRM_VERIFY } from "@/server/apis";

const ConfirmCode = ({ phone, loggedSuccess, setLoggedSuccess }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate } = useApiCreate({
    url: ADMIN_CONFIRM_VERIFY,
    action: async data => {
      if (data?.access_token) {
        console.log(data);
        message.success("Login success");
        await storeUserSession({
          access_token: data?.access_token,
          user: data?.member,
        });
        dispatch(
          loginData({
            access_token: data?.access_token,
            user: data?.member,
          })
        );
        navigate("/");
      } else {
        message.error("Login failed");
      }
    },
  });

  const onChange = async text => {
    if (!text) return;
    if (!phone) return;
    if (text.length < 6) return;
    mutate({
      phone: phone,
      code: text,
    });
  };

  return (
    <Modal
      title="Confirm Code number"
      centered
      open={loggedSuccess}
      onCancel={() => setLoggedSuccess(false)}
      width={300}>
      <div style={{ height: 150 }}>
        <Space direction="vertical">
          <Input.OTP inputMode="numeric" formatter={str => str.toUpperCase()} onChange={onChange} />
        </Space>
      </div>
    </Modal>
  );
};

export default ConfirmCode;
