import React from "react";
import { Input, Space } from "antd";
import { runes } from "runes2";

const SearchInput = ({ search, setSearch }) => {
  return (
    <Input
        count={{
          show: true,
          strategy: txt => runes(txt).length,
        }}
        value={search}
        placeholder="Search users"
        defaultValue={search}
        onChange={e => setSearch(e.target.value)}
        allowClear
        style={{
          width: "100%",
        }}
      />
  );
};

export default SearchInput;
