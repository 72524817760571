import { Form, Input } from "antd";
import React from "react";

const { TextArea } = Input;

const ServiceDescription = ({ error }) => {
  return (
    <Form.Item
      label="Description ( Explain your service )"
      name="description"
      labelCol={{ span: 24 }}
      style={{ width: "100%" }}
      rules={[{ required: true, message: error || "Please enter description." }]}
      required>
      <TextArea rows={4} placeholder={"Type Your Service Description"} name="description" />
    </Form.Item>
  );
};

export default ServiceDescription;
