import { Form, Input } from "antd";
import React from "react";

const { TextArea } = Input;

const ProductDescription = () => {
  return (
    <Form.Item
      label="Description (e.g It is a good product)"
      name="description"
      labelCol={{ span: 24 }}
      style={{ width: "100%" }}
      rules={[{ required: true, message: "Please enter description." }]}
      required>
      <TextArea rows={4} placeholder="Type your product's description here" name="description" />
    </Form.Item>
  );
};

export default ProductDescription;
