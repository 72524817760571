import { CButton, CModal } from "@/App/common";
import { ProductLikeList } from "@/App/features";
import { useMobileDimensions } from "@/hooks";
import { PlusOutlined } from "@ant-design/icons";
import { Select, SelectProps, Space } from "antd";
import React, { useState } from "react";

const options: SelectProps["options"] = [];

for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}

const handleChange = (value: string | string[]) => {
  console.log(`Selected: ${value}`);
};

const ProductLikeModel = ({ visible, setVisible }) => {
  const { isMobile } = useMobileDimensions();
  const [addLike, setAddLike] = useState(false);

  return (
    <CModal
      title="Likes"
      footer={null}
      open={visible}
      onCancel={() => {
        setVisible(false);
      }}
      width={isMobile ? "100%" : "30%"}
      bodyStyle={{
        maxHeight: isMobile ? "60vh" : "40vh",
      }}>
      {/* <div style={{ width: "100%", justifyContent: "flex-end" }}>
        {addLike ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: 8,
              alignItems: "center",
            }}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={handleChange}
              options={options}
            />
            <CButton type={"primary"} onClick={() => setAddLike(false)}>
              Save
            </CButton>
            <CButton onClick={() => setAddLike(false)}>
              Cancel
            </CButton>
          </div>
        ) : (
          <Space style={{ width: "100%", justifyContent: "flex-end" }}>
            <CButton type={"primary"} icon={<PlusOutlined />} onClick={() => setAddLike(true)}>
              Add
            </CButton>
          </Space>
        )}
      </div> */}
      <ProductLikeList />
    </CModal>
  );
};

export default ProductLikeModel;
